





















import { Component, Vue } from 'vue-property-decorator'

@Component({
  data() {
    return {

    }
  },
})
export default class InvestmentCompleted extends Vue {}
